import Splide from "@splidejs/splide";

// --------------------------------------------------
// Logo Slide on Home Page
// --------------------------------------------------

const logo = document.querySelector(".navbar__logo--home .navbar__logo-image");

if (logo) {
  const dates = document.querySelector(".navbar__dates");
  const vh50Point = 0.5 * window.innerHeight;

  let lastScrollY = window.scrollY;

  window.addEventListener("scroll", () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > vh50Point && currentScrollY > lastScrollY) {
      // Scrolling down past 50vh
      logo.classList.add("in-view");
      dates.classList.remove("in-view");
    } else if (currentScrollY < vh50Point && currentScrollY < lastScrollY) {
      // Scrolling up before 50vh
      logo.classList.remove("in-view");
      dates.classList.add("in-view");
    }

    lastScrollY = currentScrollY;
  });
}

// --------------------------------------------------
// Overlay menu
// --------------------------------------------------

document.addEventListener("DOMContentLoaded", function () {
  const menuToggle = document.getElementById("menu-toggle");
  const overlay = document.getElementById("overlay");
  const navbar = document.getElementById("navbar");
  const logoImage = document.querySelector(".navbar__logo-image");

  menuToggle.addEventListener("click", function () {
    const isOpen = overlay.classList.toggle("active");

    menuToggle.textContent = isOpen ? "Close" : "Menu";
    menuToggle.setAttribute("aria-expanded", isOpen);
    navbar.classList.toggle("navbar--dark", isOpen);

    if (logoImage) {
      if (isOpen) {
        logoImage.dataset.originalSrc = logoImage.src; // Store original src
        logoImage.src = logoImage.dataset.versionLight;
      } else {
        logoImage.src = logoImage.dataset.originalSrc;
      }
    }

    // Manage focus
    if (isOpen) {
      overlay.removeAttribute("hidden");
      overlay.querySelector("a")?.focus(); // Focus on the first link
    } else {
      overlay.setAttribute("hidden", "");
      menuToggle.focus(); // Return focus to button
    }
  });

  // Close menu with Escape key
  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape" && overlay.classList.contains("active")) {
      menuToggle.click();
    }
  });
});

// --------------------------------------------------
// Festival trailer video player
// --------------------------------------------------
const festivalTrailer = document.getElementById("festival-trailer");
if (festivalTrailer) {
  const playPause = document.getElementById("play-pause");
  const mute = document.getElementById("mute");

  function changeButtonState(type) {
    if (type === "play-pause") {
      // Play/Pause button
      if (festivalTrailer.paused) {
        playPause.setAttribute("data-state", "play");
        playPause.textContent = "Play";
      } else {
        playPause.setAttribute("data-state", "pause");
        playPause.textContent = "Pause";
      }
    } else if (type === "mute") {
      // Mute button
      mute.setAttribute(
        "data-state",
        festivalTrailer.muted ? "unmute" : "mute"
      );

      // Play/Pause button
      if (festivalTrailer.muted) {
        mute.textContent = "Unmute";
      } else {
        mute.textContent = "Mute";
      }
    }
  }
  festivalTrailer.addEventListener(
    "play",
    () => {
      changeButtonState("play-pause");
    },
    false
  );

  festivalTrailer.addEventListener(
    "pause",
    () => {
      changeButtonState("play-pause");
    },
    false
  );

  mute.addEventListener("click", (e) => {
    festivalTrailer.muted = !festivalTrailer.muted;
    changeButtonState("mute");
  });

  playPause.addEventListener("click", (e) => {
    if (festivalTrailer.paused || festivalTrailer.ended) {
      festivalTrailer.play();
    } else {
      festivalTrailer.pause();
    }
  });
}

// --------------------------------------------------
// Youtube Trailer Modals
// --------------------------------------------------

/* Toggle Video Modal
  -----------------------------------------*/
const videoTriggers = document.querySelectorAll(".js-trigger-video-modal");
if (videoTriggers) {
  console.log(videoTriggers);
  function toggle_video_modal() {
    // Click on video thumbnail or link
    const videoTriggers = document.querySelectorAll(".js-trigger-video-modal");
    videoTriggers.forEach(function (trigger) {
      trigger.addEventListener("click", function (e) {
        // prevent default behavior for a-tags, button tags, etc.
        e.preventDefault();

        // Grab the video ID from the element clicked

        // Grab the video ID from the element clicked
        // Function to get URL parameter value
        function getUrlParameter(name, url) {
          var urlParams = new URLSearchParams(new URL(url).search);
          return urlParams.get(name);
        }
        var href = this.getAttribute("data-link");
        var vValue = getUrlParameter("v", href);
        var id = vValue;

        // Autoplay when the modal appears
        // Note: this is intentionally disabled on most mobile devices
        // If critical on mobile, then some alternate method is needed
        var autoplay = "?autoplay=1";

        // Don't show the 'Related Videos' view when the video ends
        var related_no = "&rel=0";

        // String the ID and param variables together
        var src = "//www.youtube.com/embed/" + id + autoplay + related_no;

        // Pass the YouTube video ID into the iframe template...
        // Set the source on the iframe to match the video ID
        document.getElementById("youtube").setAttribute("src", src);

        // Add class to the body to visually reveal the modal
        document.body.classList.add("show-video-modal", "noscroll");
      });
    });

    // Close and Reset the Video Modal
    function close_video_modal() {
      // re-hide the video modal
      document.body.classList.remove("show-video-modal", "noscroll");

      // reset the source attribute for the iframe template, kills the video
      document.getElementById("youtube").setAttribute("src", "");
    }

    // if the 'close' button/element, or the overlay are clicked
    const closeElements = document.querySelectorAll(
      ".close-video-modal, .video-modal .video-overlay"
    );
    closeElements.forEach(function (element) {
      element.addEventListener("click", function (e) {
        close_video_modal();
      });
    });

    // if the ESC key is tapped
    document.addEventListener("keyup", function (e) {
      // ESC key maps to keycode `27`
      if (e.key === "Escape") {
        close_video_modal();
      }
    });
  }

  toggle_video_modal();
}

// --------------------------------------------------
// Program filters
// --------------------------------------------------

let filterButtons = document.querySelectorAll("#filters button");
if (filterButtons) {
  document.addEventListener("DOMContentLoaded", () => {
    const eventListings = document.querySelectorAll(".event-listing");
    const clearFiltersWrapper = document.querySelector(
      ".clear-filters-wrapper"
    );
    const clearFiltersButton = document.querySelector(".clear-filters-button");

    let activeFilters = {
      date: null,
      venue: null,
    };

    let isInitialLoad = true; // ✅ Added this flag to track the first page load

    // Function to apply filters
    function applyFilters(scrollToTop = true) {
      // ✅ Added "scrollToTop" parameter
      let anyVisible = false;

      eventListings.forEach((event) => {
        const eventDates = event.dataset.date.split(/\s+/);
        const eventVenues = event.dataset.venue.split(/\s+/);

        const matchesDate =
          !activeFilters.date || eventDates.includes(activeFilters.date);
        const matchesVenue =
          !activeFilters.venue || eventVenues.includes(activeFilters.venue);

        if (matchesDate && matchesVenue) {
          event.style.display = "grid";
          anyVisible = true;
        } else {
          event.style.display = "none";
        }
      });

      clearFiltersWrapper.style.display = anyVisible ? "none" : "block";

      const urlParams = new URLSearchParams();
      if (activeFilters.date) urlParams.set("date", activeFilters.date);
      if (activeFilters.venue) urlParams.set("venue", activeFilters.venue);
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      history.replaceState(null, "", newUrl);

      // ✅ Scroll to top only if it's NOT the initial load
      if (!isInitialLoad && scrollToTop) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }

    // Function to initialize filters from URL parameters
    function initializeFiltersFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      activeFilters.date = urlParams.get("date");
      activeFilters.venue = urlParams.get("venue");

      filterButtons.forEach((button) => {
        const filterDate = button.dataset.filterDate;
        const filterVenue = button.dataset.filterVenue;

        if (
          (filterDate && filterDate === activeFilters.date) ||
          (filterVenue && filterVenue === activeFilters.venue)
        ) {
          button.classList.add("active");
        }
      });

      applyFilters(false); // ✅ Prevent scrolling on initial load
      isInitialLoad = false; // ✅ Mark that the page has loaded
    }

    // Add event listeners to filter buttons
    filterButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const filterDate = button.dataset.filterDate;
        const filterVenue = button.dataset.filterVenue;

        if (filterDate) {
          activeFilters.date =
            activeFilters.date === filterDate ? null : filterDate;
        }
        if (filterVenue) {
          activeFilters.venue =
            activeFilters.venue === filterVenue ? null : filterVenue;
        }

        filterButtons.forEach((btn) => btn.classList.remove("active"));
        Object.keys(activeFilters).forEach((key) => {
          if (activeFilters[key]) {
            const activeButton = document.querySelector(
              `[data-filter-${key}="${activeFilters[key]}"]`
            );
            if (activeButton) {
              activeButton.classList.add("active");
            }
          }
        });

        applyFilters(); // ✅ Default behavior (scrolls to top)
      });
    });

    clearFiltersButton.addEventListener("click", () => {
      activeFilters.date = null;
      activeFilters.venue = null;
      filterButtons.forEach((button) => button.classList.remove("active"));
      applyFilters();
    });

    initializeFiltersFromUrl();
  });
}

// --------------------------------------------------
// Screening carousel
// --------------------------------------------------
let screeningCarousels = document.querySelectorAll(".screening-carousel");

screeningCarousels.forEach((screeningCarousel) => {
  let screeningSplide = new Splide(screeningCarousel, {
    type: "fade",
    rewind: true,
    speed: 0,
    pauseOnHover: false,
    autoplay: false,
    arrows: true,
    pagination: true,
    perPage: 1,
    perMove: 1,
    focus: "center",
    width: "100%",
    autoHeight: true,
    updateOnMove: true,
  });

  screeningSplide.mount();

  // Auto height
  if (screeningCarousel.classList.contains("carousel--auto-height")) {
    const autoHeight = () => {
      let activeSlide =
        screeningSplide.Components.Elements.slides[screeningSplide.index];
      let splideList = screeningCarousel.querySelector(".splide__list");
      if (splideList && activeSlide) {
        splideList.style.height = activeSlide.offsetHeight + "px";
      }
    };

    autoHeight();
    screeningSplide.on("move", () => {
      autoHeight();
    });
  }
});

// --------------------------------------------------
// Product carousel
// --------------------------------------------------
let productCarousels = document.querySelectorAll(".product-carousel");

productCarousels.forEach((productCarousel) => {
  let productSplide = new Splide(productCarousel, {
    type: "fade",
    rewind: true,
    speed: 0,
    pauseOnHover: false,
    autoplay: false,
    arrows: true,
    pagination: true,
    perPage: 1,
    perMove: 1,
    focus: "center",
    width: "100%",
    autoHeight: true,
    updateOnMove: true,
  });

  productSplide.mount();

  // Auto height
  if (productCarousel.classList.contains("carousel--auto-height")) {
    const autoHeight = () => {
      let activeSlide =
        productSplide.Components.Elements.slides[productSplide.index];
      let splideList = productCarousel.querySelector(".splide__list");
      if (splideList && activeSlide) {
        splideList.style.height = activeSlide.offsetHeight + "px";
      }
    };

    autoHeight();
    productSplide.on("move", () => {
      autoHeight();
    });
  }
});

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

// --------------------------------------------------
// 💤 Loading images
// --------------------------------------------------

// document.addEventListener("DOMContentLoaded", function () {
//   const lazyImages = document.querySelectorAll(".lazy-image");

//   lazyImages.forEach((img) => {
//     img.addEventListener("load", () => {
//       img.classList.add("loaded");
//     });

//     // For images that are already in the viewport
//     if (img.complete) {
//       img.classList.add("loaded");
//     }
//   });
// });

// --------------------------------------------------
// Overlay
// --------------------------------------------------

// document.addEventListener("DOMContentLoaded", function () {
//   const headerButton = document.querySelector(".header__button");
//   const overlayButton = document.querySelector(".overlay__studio-title");
//   const overlay = document.querySelector(".overlay");
//   const veil = document.querySelector(".veil");

//   function toggleOverlay() {
//     // Toggle the overlay height using a class
//     overlay.classList.toggle("overlay--open");

//     // Toggle the veil display using a class
//     veil.classList.toggle("veil--visible");
//   }

//   // Attach the toggle functionality to both header button and veil
//   headerButton.addEventListener("click", toggleOverlay);
//   overlayButton.addEventListener("click", toggleOverlay);
//   veil.addEventListener("click", toggleOverlay);
// });
